<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
        <el-button type="danger" size="small" @click="mBox" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>
      </div>
      <el-dialog title="新增角色" :visible.sync="dialogFormVisible" width="500px">
        <el-form ref="dialogForm" :model="dialogForm" label-width="120px" size="mini" :show-message="false">
          <el-form-item label="角色名称">
            <el-input v-model="dialogForm.role_name" maxlength="10" show-word-limit placeholder="请填写角色名称"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            @click="
              dialogFormVisible = false;
              dialogForm.role_name = '';
            "
            size="small"
            >取 消</el-button
          >
          <el-button type="primary" @click="submit('dialogForm')" size="small">确 定</el-button>
        </div>
      </el-dialog>
      <el-row>
        <el-col :md="24">
          <el-table
            ref="multiTable"
            :model="roleForm"
            :data="tableData"
            class="vd_table vg_cursor"
            @selection-change="handleSelectionChange"
            @row-dblclick="dbClickJp"
            border
          >
            <el-table-column type="selection" width="48" align="center" />
            <el-table-column label="角色名称" :show-overflow-tooltip="true" prop="role_name" />
          </el-table>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { roleAPI } from '@api/modules/role';
export default {
  name: 'RoleList',
  components: {},
  data() {
    return {
      roleForm: {
        role_name: ''
      },
      dialogForm: {
        role_name: ''
      },
      dialogFormVisible: false,
      tableData: [],
      multiSelection: [],
      btn: {}
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/role_edit') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getRoles();
    },
    // 获取角色信息
    getRoles() {
      get(roleAPI.getAllRoles)
        .then(res => {
          if (res.data.code === 0) {
            this.tableData = res.data.data.list;
            this.btn = res.data.data.btn;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 多选获取部门信息
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 新增
    addRow() {
      this.dialogFormVisible = true;
    },
    //删除提示
    mBox() {
      if (this.multiSelection.length > 0) {
        this.$confirm('确定删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.delRow();
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
            this.$refs.multiTable.clearSelection();
          });
      } else {
        this.$message({
          message: '请选择至少一条',
          type: 'warning'
        });
      }
    },
    // 删除
    delRow() {
      const ids = [];
      this.multiSelection.map(item => {
        ids.push(item.role_id);
      });

      post(roleAPI.destroyRoleByIds, { role_id_list: ids })
        .then(res => {
          if (res.data.code === 0) {
            let mg = res.data.msg;
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            this.getRoles();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.getRoles();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.jump('/role_edit', { perm_id: this.$route.query.perm_id, form_id: row.role_id });
    },
    //提交表单
    submit(formName) {
      if (this.dialogForm.role_name.indexOf(' ') === -1 && this.dialogForm.role_name) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.saveInfo();
          } else {
            console.log('error submit!');
            return false;
          }
        });
      } else {
        this.$message({
          message: '角色名词不能为空',
          type: 'warning'
        });
        this.dialogFormVisible = false;
        this.dialogForm.role_name = '';
      }
    },
    // 保存
    saveInfo() {
      post(roleAPI.addRole, this.dialogForm).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: res.data.msg + '，新角色默认无权限'
          });
          this.dialogFormVisible = false;
          this.dialogForm.role_name = '';
          this.getRoles();
        } else if (res.data.code === 7) {
          this.$message({
            type: 'error',
            message: '角色重复'
          });
          this.dialogFormVisible = false;
          this.dialogForm.role_name = '';
          this.getRoles();
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_table {
  width: 20%;
}
</style>
